export const APP_ROUTE = {
    HOME: "/",
    LIVE_RADIO: "/live-radio",
    AI_RADIO: "/ai-radio",
    PODCASTS: "/podcasts",
}

export const APP_TITLE = {
    HOME: "Home",
    LIVE_RADIO: "Live Radio",
    AI_RADIO: "AI Radio",
    PODCASTS: "Podcasts",
}
