import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setProducts } from "../store/appSlice";
import { getRadioStations } from "../services/cms.service";
import PlaylistItemList from "../components/PlaylistItemList";

const AI_RADIO = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		getRadioStations(false).then((data) => {
			dispatch(setProducts({ type: "aiRadios", data }));
		});
		return () => {};
	}, [dispatch]);

	return (
		<>
			<PlaylistItemList />
		</>
	);
};

export default AI_RADIO;
