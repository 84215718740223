import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTE } from "./../../constants/index";
import { motion, useAnimation, useInView } from "framer-motion";

const CategoryPreview = () => {
	const navigate = useNavigate();

	const [callouts] = useState([
		{
			name: "AI Voice Tracking",
			imageSrc:
				"https://firebasestorage.googleapis.com/v0/b/podcastsite-1bedc.appspot.com/o/audio-files%2Fliveradio.jpg?alt=media&token=3c2d6f35-6c1f-4408-b7e3-c3568e4b6a50",
			imageAlt: "",
			href: APP_ROUTE.LIVE,
		},
		{
			name: "AI Music Programming",
			imageSrc:
				"https://firebasestorage.googleapis.com/v0/b/podcastsite-1bedc.appspot.com/o/audio-files%2Fplaylists.jpg?alt=media&token=20e61d1d-7109-4815-b695-9240aa6770b7",
			imageAlt: "",
			href: APP_ROUTE.PLAYLISTS,
		},
		{
			name: "AI News Weather Traffic",
			imageSrc:
				"https://firebasestorage.googleapis.com/v0/b/podcastsite-1bedc.appspot.com/o/audio-files%2Fpodcast.jpg?alt=media&token=f4c345c7-ee65-487f-9215-104f348fa532",
			imageAlt: "",
			href: APP_ROUTE.PODCASTS,
		},
	]);

	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });
	const mainControls = useAnimation();

	useEffect(() => {
		if (isInView) {
			mainControls.start("visible");
		}
	}, [isInView, mainControls]);

	const fadeInAnimation = {
		initial: {
			opacity: 0,
			y: 100,
		},
		animate: (index) => ({
			opacity: 1,
			y: 0,
			transition: {
				delay: 0.1 * index,
			},
		}),
	};
	return (
		<div className="bg-gray-100">
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
					<h2 className="text-2xl font-bold text-gray-900">Built by radio professionals to create an authentic radio experience</h2>

					<div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
						{callouts.map((callout, index) => (
							<motion.div
								variants={fadeInAnimation}
								initial="initial"
								whileInView="animate"
								custom={index}
								animate={mainControls}
								key={callout.name}
							>
								<div key={callout.name} className="group relative">
									<div className="relative h-80 w-full overflow-hidden rounded-2xl bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
										<img
											src={callout.imageSrc}
											alt={callout.imageAlt}
											className="absolute object-cover top-0 left-0 object-center w-full h-full rounded-t-lg group-hover:transform group-hover:scale-110 transition duration-500"
										/>
										<div className="px-5 bg-gray-900/70 backdrop-blur-sm group-hover:opacity-[1] opacity-[0] flex justify-center transition duration-500 delay-75 h-full flex-col py-12 group-hover:h-full">
											<h3 className="pointer text-lg md:text-2xl font-semibold text-white text-center">
												<div onClick={() => navigate(callout.href)}>
													<span />
													{callout.name}
												</div>
											</h3>
											<p className="text-base font-semibold text-gray-900">{callout.description}</p>
										</div>
									</div>
								</div>
							</motion.div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CategoryPreview;
