import React from "react";

const videoUrl =
	"https://firebasestorage.googleapis.com/v0/b/podcastsite-1bedc.appspot.com/o/eventvideon.mp4?alt=media&token=1e251e58-16c6-4619-a35b-31424bf7b062";

const EventPlayer = () => {
	return (
		<div className="relative overflow-hidden bg-neutral-950 h-[calc(100vh)] w-full mt-[-131px]">
			<video className="h-full w-full object-cover" autoPlay="autoPlay" muted="muted" loop="loop" controls="controls">
				<source src={videoUrl} type="video/mp4" />
			</video>

			<GradientEdges />
		</div>
	);
};

const GradientEdges = () => {
	return (
		<>
			<div className="pointer-events-none absolute bottom-0 left-0 top-0 w-[10vw] max-w-[100px] bg-gradient-to-r from-neutral-950/50 to-neutral-950/0" />
			<div className="pointer-events-none absolute bottom-0 right-0 top-0 w-[10vw] max-w-[100px] bg-gradient-to-l from-neutral-950/50 to-neutral-950/0" />
		</>
	);
};

export default EventPlayer;
