import { useDispatch, useSelector } from "react-redux";
import edmImg from "../assets/images/aitop40-logo.svg";
import React, { useState, useRef, useEffect } from "react";
import { setGlobalPlayer, setPlayGlobalPlayer } from "../store/appSlice";

const streamUrls = ["https://centova.gptradio.co/_proxy/8001/stream", "https://centova.gptradio.co/_proxy/8004/stream"];

export default function AudioPlayer() {
	const dispatch = useDispatch();
	const aux_point = useRef(null);
	const audioRef1 = useRef(null);
	const audioRef2 = useRef(null);
	const [currentRef, setCurrentRef] = useState(audioRef1);
	const [volume, setVolume] = useState(50);
	const [isLoadingAudio, setIsLoadingAudio] = useState(false);
	const { globalPlayer, playGlobalPlayer, aiRadioPlaylist } = useSelector((state) => state.appStore);

	useEffect(() => {
		setIsLoadingAudio(true);
		if (globalPlayer?.state === "initial") {
			dispatch(setPlayGlobalPlayer(false));
			audioRef1.current.src = globalPlayer?.source || streamUrls[0];
			audioRef1.current.currentTime = globalPlayer?.in_point || 0;
			setCurrentRef(audioRef1);
			audioRef2.current.pause();
			audioRef2.current.src = "";
			audioRef2.current.load();
		} else {
			if (currentRef.current === audioRef1.current) {
				audioRef2.current.src = globalPlayer?.source || streamUrls[0];
				audioRef2.current.currentTime = globalPlayer?.in_point || 0;
				setCurrentRef(audioRef2);
				fadeOutAudio(audioRef1.current);
			} else {
				audioRef1.current.src = globalPlayer?.source || streamUrls[1];
				audioRef1.current.currentTime = globalPlayer?.in_point || 0;
				setCurrentRef(audioRef1);
				fadeOutAudio(audioRef2.current);
			}
		}
		aux_point.current = globalPlayer?.aux_point || null;
		setTimeout(() => {
			dispatch(setPlayGlobalPlayer(true));
		}, 100);
	}, [globalPlayer]);

	const handlePause = () => {
		dispatch(setPlayGlobalPlayer(false));
	};

	const togglePlay = () => {
		if (playGlobalPlayer) {
			dispatch(setPlayGlobalPlayer(false));
		} else {
			dispatch(setPlayGlobalPlayer(true));
		}
	};

	useEffect(() => {
		const handleTimeUpdate = () => {
			const audioElement = currentRef.current;
			if (aux_point.current && audioElement && audioElement.currentTime >= aux_point.current) {
				playNextAudio();
			}
		};

		const audioElement1 = audioRef1.current;
		const audioElement2 = audioRef2.current;

		if (audioElement1) {
			audioElement1.addEventListener("timeupdate", handleTimeUpdate);
		}

		if (audioElement2) {
			audioElement2.addEventListener("timeupdate", handleTimeUpdate);
		}

		return () => {
			if (audioElement1) {
				audioElement1.removeEventListener("timeupdate", handleTimeUpdate);
			}
			if (audioElement2) {
				audioElement2.removeEventListener("timeupdate", handleTimeUpdate);
			}
		};
	}, [aux_point, globalPlayer, currentRef]);

	const playNextAudio = () => {
		if (globalPlayer?.type === "aiRadio") {
			let newPosition = globalPlayer?.position + 1 || 0;
			if (newPosition >= aiRadioPlaylist.length) {
				newPosition = 0;
			}
			const newPlayer = {
				type: "aiRadio",
				state: "playing",
				station_name: globalPlayer.station_name,
				position: newPosition,
				source: aiRadioPlaylist[newPosition].media.url,
				name: aiRadioPlaylist[newPosition].artist || globalPlayer.station_name,
				thumbnail: globalPlayer.thumbnail,
				description: aiRadioPlaylist[newPosition].title,
				in_point: aiRadioPlaylist[newPosition].in_point,
				aux_point: aiRadioPlaylist[newPosition].aux_point,
			};
			dispatch(setGlobalPlayer(newPlayer));
		} else {
			handlePause();
		}
	};

	const fadeOutAudio = (audioElement) => {
		const fadeDuration = 0.5;
		const fadeStep = 0.1;
		const initialVolume = audioElement.volume;

		const fadeOut = setInterval(() => {
			if (audioElement.volume > 0.1) {
				audioElement.volume -= initialVolume / (fadeDuration / fadeStep);
			} else {
				clearInterval(fadeOut);
				audioElement.pause();
				audioElement.currentTime = 0;
				audioElement.src = "";
				audioElement.load();
				audioElement.volume = initialVolume;
			}
		}, fadeStep * 1000);
	};

	useEffect(() => {
		if (!playGlobalPlayer) {
			currentRef.current.pause();
		} else {
			currentRef.current.play().catch((error) => console.log("Line 136", error));
		}
	}, [playGlobalPlayer, currentRef]);

	const handleVolumeChange = (event) => {
		const newVolume = parseFloat(event.target.value);
		setVolume(newVolume);
		if (currentRef.current) {
			currentRef.current.volume = newVolume / 100;
		}
	};

	const handleCanPlayAudio = () => {
		setIsLoadingAudio(false);
	};

	return (
		<div className="fixed bottom-0 left-0 z-50 w-full h-20 bg-gradient-to-r from-black to-gray-900">
			<audio onCanPlay={handleCanPlayAudio} ref={audioRef1} />
			<audio onCanPlay={handleCanPlayAudio} ref={audioRef2} />
			<div className="max-w-6xl grid px-8 w-full mx-auto h-full grid-cols-3 ">
				<div className="w-full flex items-center justify-start mr-auto gap-2">
					<img className="sm:h-16 h-12 mr-3 rounded" src={globalPlayer?.thumbnail || edmImg} alt="Video preview" />
					<div>
						<p className="text-sm w-max text-white md:block hidden">{globalPlayer?.name || "AI Radio One"}</p>
						<p className="mt-1 text-base font-medium text-white">{globalPlayer?.description || "The Hit Music Station"}</p>
					</div>
				</div>
				<div className="flex items-center w-full">
					{/* player buttons */}
					<div className="flex items-center justify-center mx-auto mb-1">
						{isLoadingAudio ? (
							<LoadingSpinner />
						) : (
							<div className="flex items-center gap-2 justify-center transition duration-200 w-full">
								<button data-tooltip-target="tooltip-pause" type="button" className="w-8 h-8 opacity-0">
									<svg
										version="1.1"
										id="next"
										xmlns="http://www.w3.org/2000/svg"
										xmlnsXlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 100 100"
										enableBackground="new 0 0 100 100"
										xmlSpace="preserve"
									>
										<path
											className="stroke-solid"
											fill="none"
											stroke="#ffffff"
											d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7C97.3,23.7,75.7,2.3,49.9,2.5"
										/>
										<>
											<path
												strokeLinecap="round"
												fill="#ffffff"
												className="transform translate-x-[24px] translate-y-[20px] scale-[2.25]"
												strokeLinejoin="round"
												d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z"
											/>
										</>
									</svg>
								</button>
								<button onClick={togglePlay} data-tooltip-target="tooltip-pause" type="button" className="w-10 h-10 mx-4">
									<svg
										version="1.1"
										id="play"
										xmlns="http://www.w3.org/2000/svg"
										xmlnsXlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 100 100"
										enableBackground="new 0 0 100 100"
										xmlSpace="preserve"
									>
										<path
											className="stroke-solid"
											fill="none"
											stroke="#ffffff"
											d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7C97.3,23.7,75.7,2.3,49.9,2.5"
										/>
										{playGlobalPlayer ? (
											<>
												<path
													strokeLinecap="round"
													stroke="#ffffff"
													strokeWidth={2}
													className="transform translate-x-[22px] translate-y-[20px] scale-[2.5]"
													strokeLinejoin="round"
													d="M15.75 5.25v13.5m-7.5-13.5v13.5"
												/>
											</>
										) : (
											<>
												<path
													strokeLinecap="round"
													fill="#ffffff"
													className="transform translate-x-[24px] translate-y-[20px] scale-[2.5]"
													strokeLinejoin="round"
													d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
												/>
											</>
										)}
									</svg>
									<span className="sr-only">Pause video</span>
								</button>
								<button
									onClick={playNextAudio}
									data-tooltip-target="tooltip-pause"
									type="button"
									className={globalPlayer?.type === "aiRadio" ? "w-8 h-8" : "w-8 h-8 opacity-0"}
								>
									<svg
										version="1.1"
										id="next"
										xmlns="http://www.w3.org/2000/svg"
										xmlnsXlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 100 100"
										enableBackground="new 0 0 100 100"
										xmlSpace="preserve"
									>
										<path
											className="stroke-solid"
											fill="none"
											stroke="#ffffff"
											d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7C97.3,23.7,75.7,2.3,49.9,2.5"
										/>
										<>
											<path
												strokeLinecap="round"
												fill="#ffffff"
												className="transform translate-x-[24px] translate-y-[20px] scale-[2.25]"
												strokeLinejoin="round"
												d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z"
											/>
										</>
									</svg>
								</button>
							</div>
						)}
					</div>
				</div>
				<div className="flex items-center justify-end w-full">
					<div className="group relative cursor-pointer volume-b">
						{volume === 0 ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									className="text-white"
									strokeLinejoin="round"
									d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="w-6 h-6"
							>
								<path
									strokeLinecap="round"
									className="text-white"
									strokeLinejoin="round"
									d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
								/>
							</svg>
						)}

						<div className="absolute p-2 hidden range-c group-hover:flex hover:flex w-[120px] right-[-50px] top-[-80px] transform rotate-[270deg]">
							<div className="bg-white rounded-md p-2 flex">
								<input
									type="range"
									min="0"
									max="100"
									value={volume}
									onChange={handleVolumeChange}
									className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function LoadingSpinner() {
	return (
		<div role="status">
			<svg
				aria-hidden="true"
				className="w-8 h-8 mr-2 text-gray-900 animate-spin dark:text-gray-900 fill-blue-600"
				viewBox="0 0 100 101"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
					fill="currentColor"
				/>
				<path
					d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
					fill="currentFill"
				/>
			</svg>
			<span className="sr-only">Loading...</span>
		</div>
	);
}
