import Reveal from '../../shared/Reveal';
import AppleStoreIcon from './../../assets/images/apple-store.svg';

export default function Example() {
  return (
    <div className="relative bg-gray-900">
      <div className="relative h-80 overflow-hidden bg-indigo-600 md:absolute md:left-0 md:h-full xl:w-1/3 2xl:w-1/2">
        <img
          className="h-full w-full object-cover"
          src="https://firebasestorage.googleapis.com/v0/b/podcastsite-1bedc.appspot.com/o/audio-files%2F5f034ca7otomatikguncleleme.jpg?alt=media&token=64113b8f-5ef4-4839-8e4c-14877a19ee4d"
          alt=""
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 926 676"
          aria-hidden="true"
          className="absolute left-24 -bottom-24 w-[57.875rem] transform-gpu blur-[118px]"
        >
          <path
            fill="url(#60c3c621-93e0-4a09-a0e6-4c228a0116d8)"
            fillOpacity=".4"
            d="m254.325 516.708-90.89 158.331L0 436.427l254.325 80.281 163.691-285.15c1.048 131.759 36.144 345.144 168.149 144.613C751.171 125.508 707.17-93.823 826.603 41.15c95.546 107.978 104.766 294.048 97.432 373.585L685.481 297.694l16.974 360.474-448.13-141.46Z"
          />
          <defs>
            <linearGradient
              id="60c3c621-93e0-4a09-a0e6-4c228a0116d8"
              x1="926.392"
              x2="-109.635"
              y1=".176"
              y2="321.024"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#776FFF" />
              <stop offset={1} stopColor="#FF4694" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="md:bg-gray-900/70 md:backdrop-blur-sm xl:backdrop-blur-0 xl:bg-transparent relative mx-auto max-w-7xl py-24 sm:py-32 lg:py-40 lg:px-8">
        <div className="pr-6 pl-6 md:ml-auto xl:w-2/3 md:pl-16 2xl:w-1/2 lg:pr-0 xl:pl-32">
          <Reveal
            hidden={{ opacity: 0, y: -100 }}
            visible={{ opacity: 1, y: 1 }}
            width="full"
          >
            <h2 className="text-base font-semibold leading-7 text-indigo-400">Available on App Store</h2>
          </Reveal>
          <Reveal
            hidden={{ opacity: 0, x: 100 }}
            visible={{ opacity: 1, x: 1 }}
            width="full"
          >
            <p className="mt-2 text-4xl font-bold tracking-tight text-white">Bring AI RadioPlayer with you anywhere</p>
          </Reveal>
          <div className="mt-6 text-base leading-7 text-gray-300">
            <Reveal
              hidden={{ opacity: 0, y: 100 }}
              visible={{ opacity: 1, y: 1 }}
              width="full"
            >
              Download the free app from the App Store and start listening to your favorite music.
            </Reveal>
          </div>
          <Reveal
            hidden={{ opacity: 0, y: 100 }}
            visible={{ opacity: 1, y: 1 }}
            width="full"
          >
            <div className="mt-8">
              <a
                href="https://www.apple.com/app-store/" target={"_blank"} rel="noreferrer"
                className="inline-flex rounded-md bg-white/10 text-base font-semibold leading-7 text-white shadow-sm hover:bg-white/20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                <img alt="Apple Store" src={AppleStoreIcon} />
              </a>
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  )
}
