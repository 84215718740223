import { APP_ROUTE, APP_TITLE } from '../constants';
import Home from './../pages/Home';
import LiveRadio from '../pages/LiveRadio';
import AI_RADIO from '../pages/AiRadio';
import Podcasts from './../pages/Podcasts';

const routes = [
    {
        name: APP_TITLE.HOME,
        route: APP_ROUTE.HOME,
        component: <Home />
    },
    {
        name: APP_TITLE.LIVE_RADIO,
        route: APP_ROUTE.LIVE_RADIO,
        component: <LiveRadio />
    },
    {
        name: APP_TITLE.AI_RADIO,
        route: APP_ROUTE.AI_RADIO,
        component: <AI_RADIO />
    },
    {
        name: APP_TITLE.PODCASTS,
        route: APP_ROUTE.PODCASTS,
        component: <Podcasts />
    },
];

export default routes;
