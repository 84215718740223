import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalPlayer } from "../store/appSlice";

const LiveItemList = () => {
	const dispatch = useDispatch();
	const { searchText, liveRadios } = useSelector((state) => state.appStore);
	const [items, setItems] = useState([]);

	useEffect(() => {
		if (searchText === "") {
			setItems(liveRadios);
		} else {
			setItems(
				liveRadios?.filter((item) => {
					return (
						item?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
						item?.description?.toLowerCase()?.includes(searchText?.toLowerCase())
					);
				}),
			);
		}
		return () => {};
	}, [searchText, liveRadios]);

	const handlePlay = (item) => {
		const new_item = {
			type: "liveRadio",
			state: "initial",
			station_name: item.name,
			source: item.streaming.url,
			name: item.name,
			thumbnail: item.logo,
			description: item.description,
		};
		dispatch(setGlobalPlayer(new_item));
	};

	return (
		<div className="bg-white">
			<div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
				<h2 className="sr-only">Products</h2>

				<div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
					{items?.map((product, key) => (
						<div key={key} className="live-grid-item" onClick={() => handlePlay(product)}>
							<div className="group">
								<div className="live-group-item aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-8">
									<img alt={product.name} src={product.logo} className="h-full w-full object-cover object-center group-hover:opacity-75" />
									<div className="live-play-button-container">
										<button className="play" type="button">
											<span className="SVGInline">
												<svg className="SVGInline-svg" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" viewBox="256 908 100 100">
													<style></style>
													<path d="M272.6 912.3l69.9 44c1.8 1.1 1.8 3.7 0 4.8l-69.9 44c-1.9 1.2-4.3-.2-4.3-2.4v-87.9c0-2.3 2.5-3.7 4.3-2.5z"></path>
												</svg>
											</span>
										</button>
									</div>
								</div>
								<h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
								<p className="mt-1 text-lg font-medium text-gray-900">{product.description}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default LiveItemList;
