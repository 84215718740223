import { useSelector } from "react-redux";
import randomLogo from "../assets/images/latestLogo.svg";
import Reveal from "../shared/Reveal";
import AudioPlayer from "./AudioPlayer";
import EventPlayer from "./EventPlayer";

export default function Header() {
	const { globalPlayer } = useSelector((state) => state.appStore);
	return (
		<div>
			<div className="sticky grid place-items-center top-0 z-10 w-full isolate overflow-hidden py-[18px] px-10 backdrop-blur-sm">
				<Reveal
					width="full"
					hidden={{ opacity: 0, y: -100 }}
					visible={{ opacity: 1, y: 0 }}
					className={`bg-gray-700/70 backdrop-blur-sm tranmsition duration-200 py-4 relative flex items-center justify-center px-6 rounded-[100vw] w-full  mx-auto `}
				>
					<div className="flex items-center justify-between w-full max-w-[95%]">
						<Reveal hidden={{ opacity: 0, x: -100 }} visible={{ opacity: 1, x: 0 }} width="full">
							<img src={randomLogo} alt="logo" className="w-64" />
						</Reveal>
					</div>
				</Reveal>
			</div>
			<EventPlayer />
			{globalPlayer ? <AudioPlayer /> : <></>}
		</div>
	);
}
