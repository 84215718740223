import { configureStore } from '@reduxjs/toolkit'
import appSlice from './appSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';


const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, appSlice);

export const store = configureStore({
  reducer: {
    appStore: persistedReducer,
  },
  middleware: [thunk]
})


export const persistor = persistStore(store)