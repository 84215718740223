import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const PodcastItemList = () => {

    const { searchText, podcasts } = useSelector((state) => state.appStore);
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (searchText === "") {
            setItems(podcasts)
        } else {
            setItems(podcasts?.filter((item) => {
                return (
                    (item?.name?.toLowerCase() ?? "")?.includes(searchText?.toLowerCase()) 
                    || 
                    (item?.description?.toLowerCase() ?? "")?.includes(searchText?.toLowerCase())
                )
            }))
        }
        return () => { };
    }, [searchText, podcasts]);

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                <h2 className="sr-only">Products</h2>
                <div className="grid grid-cols-1 gap-y-5 gap-x-1 sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 xl:gap-x-8">
                    {items?.map((product, key) => (
                        <div key={key} className="gorup">
                            <div className="podcast-image aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-8">
                                <img
                                    alt={product?.name}
                                    src={product?.img}
                                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                                />
                            </div>
                            <div className="podcast-details bg-gray-50 px-2 py-6">
                                <h3 className="mt-4 text-lg text-gray-900">{product?.name}</h3>
                                <p className="mt-1 text-sm text-gray-500">{product?.description}</p>
                            </div>
                            <div className='podcast-play-container py-2 px-12 bg-gray-75'>
                                <audio controls>
                                    <source
                                        src={product?.audio_url}
                                        type="audio/mp3"
                                    />
                                </audio>
                            </div>                            
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PodcastItemList;


