import PodcastItemList from "./../components/PodcastItemList";
import { useEffect } from "react";
import { getProducts } from "../services/firebase.service";
import { useDispatch } from "react-redux";
import { setGlobalPlayer, setPlayGlobalPlayer, setProducts } from "../store/appSlice";

const Playlists = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setGlobalPlayer(null));
		dispatch(setPlayGlobalPlayer(false));

		getProducts("podcasts", "name").then((data) => {
			dispatch(setProducts({ data, type: "podcasts" }));
		});
		return () => {};
	}, [dispatch]);

	return (
		<>
			<PodcastItemList />
		</>
	);
};

export default Playlists;
