const CurrentRole = ({ show }) => {
  return (
    <div
      className={`border-b border-gray-900/10 pb-12 ${show ? '' : 'hidden'}`}
    >
      <h2 className='text-base font-semibold leading-7 text-gray-900'>
        Current Role
      </h2>
      <p className='mt-1 text-sm leading-6 text-gray-600'>
        What best describes your current role?
      </p>

      <div className='mt-10 grid grid-cols-12 gap-x-6 gap-y-8 '>
        <fieldset>
          <div className='mt-6 space-y-6'>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='seniorManager'
                  name='currentRole'
                  type='checkbox'
                  value='Senior Manager'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6'>
                <label
                  htmlFor='seniorManager'
                  className='font-medium text-gray-900'
                >
                  Senior Manager
                </label>
              </div>
            </div>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='departmentManager'
                  name='currentRole'
                  type='checkbox'
                  value='Department Manager'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6'>
                <label
                  htmlFor='departmentManager'
                  className='font-medium text-gray-900'
                >
                  Department Manager
                </label>
              </div>
            </div>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='talent'
                  name='currentRole'
                  type='checkbox'
                  value='Talent'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6'>
                <label htmlFor='talent' className='font-medium text-gray-900'>
                  Talent
                </label>
              </div>
            </div>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='production'
                  name='currentRole'
                  type='checkbox'
                  value='Production'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6'>
                <label
                  htmlFor='production'
                  className='font-medium text-gray-900'
                >
                  Production
                </label>
              </div>
            </div>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='consultant'
                  name='currentRole'
                  type='checkbox'
                  value='Consultant'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6'>
                <label
                  htmlFor='consultant'
                  className='font-medium text-gray-900'
                >
                  Consultant
                </label>
              </div>
            </div>
            <div className='relative flex gap-x-3'>
              <div className='flex h-6 items-center'>
                <input
                  id='other'
                  name='currentRole'
                  type='checkbox'
                  value='Other'
                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                />
              </div>
              <div className='text-sm leading-6 flex gap-2'>
                <label htmlFor='other' className='font-medium text-gray-900'>
                  Other
                </label>
                <div className='flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md'>
                  <input
                    type='text'
                    name='otherRole'
                    id='otherRole'
                    autoComplete='otherRole'
                    className='block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6'
                  />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default CurrentRole;
