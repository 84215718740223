import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";

export const appSlice = createSlice({
	name: "appStore",
	initialState,
	reducers: {
		setSearchText: (state, action) => {
			state.searchText = action.payload;
		},
		setProducts: (state, action) => {
			state[action.payload.type] = action.payload.data;
		},
		setGlobalPlayer: (state, action) => {
			state.globalPlayer = action.payload;
		},
		setPlayGlobalPlayer: (state, action) => {
			state.playGlobalPlayer = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setSearchText, setProducts, setGlobalPlayer, setPlayGlobalPlayer } = appSlice.actions;

export default appSlice.reducer;
