import FrontpageTop from "../components/home/FrontpageTop";
import CategoryPreview from "../components/home/CategoryPreview";
import DownloadApp from "../components/home/DownloadApp";
import { setGlobalPlayer, setPlayGlobalPlayer } from "../store/appSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
// import Contact from '../components/ContactForm';

const Home = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setGlobalPlayer(null));
		dispatch(setPlayGlobalPlayer(false));
		return () => {};
	}, [dispatch]);

	return (
		<>
			<div className="my-12">
				{/** Top Section */}
				<FrontpageTop />
				{/** Category Section */}
				<CategoryPreview />
				{/* <Contact /> */}
				{/** Download App section */}
				<DownloadApp />
			</div>
		</>
	);
};

export default Home;
