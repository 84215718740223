const ThankYou = ({ show }) => {
  return (
    <div
      className={`border-b border-gray-900/10 pb-12 ${show ? '' : 'hidden'}`}
    >
      <h2 className='text-base font-semibold leading-7 text-gray-900'>
        Thank You!
      </h2>
      <p className='mt-1 text-sm leading-6 text-gray-600'>
        Thank you for taking our survey. Your response is very important to us.
      </p>
    </div>
  );
};

export default ThankYou;
